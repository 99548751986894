import { useRef } from "react";
import styles from "./FrontSection.module.scss";
import Section from "../Section";

export default function FrontSection() {
  const section1 = useRef();
  return (
    <div ref={section1}>
      <Section
        animate={false}
        sectionClass={`bg-black`}
        headline={
          <div className={`flex-centered ` + styles.container}>
            <span className={styles.weAre}>we are&nbsp;</span>
            <span>rarity [</span>
            <span className={`${styles.icon}`} />
            <span>] digital</span>
          </div>
        }
      >
        <div className={styles.rarityR}></div>
      </Section>
    </div>
  );
}
