import "./App.css";
import styles from "./App.module.scss";
import FrontSection from "./components/Sections/FrontSection";
import WhoSection from "./components/Sections/WhoSection";
import OurSection from "./components/Sections/OurSection";
import ExperienceSection from "./components/Sections/ExperienceSection";
import WhatSection from "./components/Sections/WhatSection";
import ContactFormSection from "./components/Sections/ContactFormSection";

function App() {
  return (
    <div className="App">
      <header></header>
      <main className={`container ${styles.container}`}>
        <FrontSection />
        <WhoSection />
        <OurSection />
        <ExperienceSection />
        <WhatSection />
        <ContactFormSection />
      </main>
    </div>
  );
}

export default App;
