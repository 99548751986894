import { useRef } from "react";
import styles from "./ExperienceSection.module.scss";
import Section from "../Section";

export default function ExperienceSection() {
  const section4 = useRef();

  return (
    <div ref={section4}>
      <Section
        sectionClass={`bg-tertiary overflow-clip`}
        containerClass={`full-width min-full-height flex-centered flex-column`}
        headline={
          <>
            <div className={styles.text}>
              our team has combined
              <br />
              experience working with
            </div>
            <div
              className={`primary underline ${styles.underlined} ${styles.text}`}
            >
              top tier clients
            </div>
          </>
        }
      >
        <>
          <div className={styles.slidetrack}>
            <div className={styles.container}>
              <div className={styles.firstlight} />
            </div>
            <div className={styles.container}>
              <div className={styles.mancity} />
            </div>
            <div className={styles.container}>
              <div className={styles.okx} />
            </div>
            <div className={styles.container}>
              <div className={styles.mclaren} />
            </div>
            <div className={styles.container}>
              <div className={styles.donkeyblx} />
            </div>
            <div className={styles.container}>
              <div className={styles.firstlight} />
            </div>
          </div>
        </>
      </Section>
    </div>
  );
}
