import { useState } from "react";
import styles from "./Details.module.scss";
import { ReactComponent as PlusSvg } from "../assets/plus.svg";

export default function Details({ summaryText, children }) {
  const [opened, setOpened] = useState(false);

  return (
    <details className={opened ? "open" : "closed"}>
      <summary onClick={() => setOpened(!opened)}>
        <span>{summaryText}</span>
        <span>
          <PlusSvg />
        </span>
      </summary>
      <div className={styles.detailsContent}>{children}</div>
    </details>
  );
}
