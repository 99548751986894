import { useEffect, useRef } from "react";
import styles from "./Section.module.scss";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import EaseAnimation from "../helpers/EaseAnimation";

// gsap.registerPlugin(ScrollTrigger);

export default function Section({
  animate = true,
  sectionClass,
  headline,
  id,
  containerClass = "",
  children,
}) {
  const headlineRef = useRef();
  const sectionRef = useRef();

  useEffect(() => {
    if (animate) {
      EaseAnimation(headlineRef);
    }

    return () => {};
  }, [animate]);
  return (
    <div
      id={id}
      className={`${styles.section} ${sectionClass}`}
      ref={sectionRef}
    >
      <div className={`${styles.copy} ${containerClass}`}>
        {headline && <h2 ref={headlineRef}>{headline}</h2>}
        {children}
      </div>
    </div>
  );
}
