import { useEffect, useRef } from "react";
import styles from "./WhoSection.module.scss";
import Section from "../Section";
import EaseAnimation from "../../helpers/EaseAnimation";

export default function WhoSection() {
  const section2 = useRef();
  const headline2 = useRef();
  const image2 = useRef();
  const text2 = useRef();

  useEffect(() => {
    EaseAnimation(headline2, 100);
    EaseAnimation(image2, 100);
    EaseAnimation(text2);
    return () => {};
  }, []);

  return (
    <div ref={section2}>
      <Section
        sectionClass={`bg-tertiary`}
        containerClass={
          `full-width min-full-height two-column reverse ` + styles.whoWeAre
        }
        headline={null}
      >
        <div ref={image2} className={`r-image`}></div>
        <div>
          <h2 ref={headline2}>
            who <span className={`underline`}>we are</span>
          </h2>
          <p ref={text2}>
            We are a London boutique agency that helps businesses craft
            impactful social media strategies, strong identities, and innovative
            web3/AI solutions to succeed in the digital age.
          </p>
          <a href="#services">services</a>
        </div>
      </Section>
    </div>
  );
}
