import styles from "./ContactFormSection.module.scss";
import Section from "../Section";
import { useState } from "react";
import { send } from "emailjs-com";

export default function ContactFormSection() {
  const [toSend, setToSend] = useState({
    from_name: "",
    help_message: "",
    reply_to: "",
    budget: "",
    timeframe: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_hxcg18o", "template_z28978j", toSend, "Yu3hLaQWI5nwtHVmE")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        e.currentTarget.reset();
        alert(
          `An email has been sent. (status: ${response.status})\nPlease check the email you provided for confirmation.`
        );
      })
      .catch((err) => {
        alert(
          `Failed to send email.\n${err.text}\n\n If the issue persists contact daniellemichellew@googlemail.com`
        );
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  /**
   * Returns number in required format with currency appended.
   *
   * @param {Event} e The event to check.
   * @return {string} Comma seperated number with currency.
   */
  const formatCurrency = (e) => {
    // get input value
    let input = e.currentTarget;
    let input_val = input.value;

    // don't validate empty input
    if (input_val === "") {
      return;
    }

    // original length
    const original_len = input_val.length;
    // initial caret position
    const caret_pos = input.selectionStart;
    // use dollar if user prompts otherwise pound
    const currency = input_val[0] === "$" ? "$" : "£";

    // format number eg: 1000000 to £1,000,000
    input_val =
      currency +
      input_val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // send updated string to input
    input.value = input_val;

    input.selectionStart = input.selectionEnd =
      input_val.length - original_len + caret_pos;
  };

  return (
    <div>
      <Section
        sectionClass={`bg-black`}
        containerClass={styles.contactForm}
        headline={
          <div>
            let's start
            <br />
            your journey today<span>.</span>
          </div>
        }
      >
        <form onSubmit={onSubmit} className={styles.form}>
          <label className={styles.label} htmlFor="from_name">
            👋 hey, my name is
          </label>
          <input
            className={styles.input}
            type={"text"}
            placeholder="John Smith"
            name="from_name"
            onChange={handleChange}
          />
          <br />
          <label className={styles.label} htmlFor="reply_to">
            my email address is
          </label>
          <input
            className={styles.input}
            type={"email"}
            placeholder="john.smith@mail.com"
            name="reply_to"
            onChange={handleChange}
          />
          <br />
          <label className={styles.label} htmlFor="help_message">
            i am looking for help with
          </label>
          <input
            className={styles.input}
            type={"text"}
            name="help_message"
            placeholder="content creation"
          />
          <br />
          <label className={styles.label} htmlFor="budget">
            my maximum budget is
          </label>
          <input
            className={styles.input}
            type={"text"}
            name="budget"
            placeholder="£2,500"
            // pattern={"^[$£]d{1,3}(,d{3})*(.d+)?$"}
            data-type="currency"
            onKeyUp={(e) => formatCurrency(e)}
            onBlur={(e) => formatCurrency(e)}
            onChange={handleChange}
          />
          <br />
          <label className={styles.label} htmlFor="timeframe">
            i need my project within
          </label>
          <input
            className={styles.input}
            type={"text"}
            name="timeframe"
            placeholder="3 months"
            onChange={handleChange}
          />
          <input className={styles.input} type={"submit"} value="send" />
        </form>
      </Section>
    </div>
  );
}
