import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function EaseAnimation(
  ref,
  yStart = -100,
  animation = "power4.out"
) {
  return gsap.fromTo(
    ref.current,
    {
      autoAlpha: 0,
      y: yStart,
    },
    {
      y: 0,
      autoAlpha: 1,
      duration: 1,
      ease: animation,
      scrollTrigger: {
        scroller: ".container",
        trigger: ref.current,
        start: "top 60%",
        end: "bottom 0%",
        toggleActions: "play none restart reverse",
      },
    }
  );
}
