import { useEffect, useRef } from "react";
import styles from "./WhatSection.module.scss";
import Section from "../Section";
import Details from "../Details";
import EaseAnimation from "../../helpers/EaseAnimation";

export default function WhatSection() {
  const section4 = useRef();
  const content4 = useRef();

  useEffect(() => {
    EaseAnimation(content4);
    return () => {};
  }, []);

  return (
    <div ref={section4}>
      <Section
        sectionClass={`bg-secondary`}
        containerClass={
          `full-width min-full-height two-column ` + styles.whatSection
        }
        headline={
          <>
            what <span className={`underline`}>we do</span>
          </>
        }
        id={"services"}
      >
        <div ref={content4} className={styles.detailsContainer}>
          <Details summaryText={`Social Media Starter Pack 📱`}>
            <p>
              Get your brand off the ground with our Social Media Starter Pack.
              Designed for absolute beginners, our team of experts will assist
              with setting up and optimizing social media accounts and crafting
              on-brand content. From start to finish, we'll help kickstart your
              online presence and build the foundation needed to cultivate a
              devoted community.
            </p>
            <p>
              <strong>Who is it for?</strong>
              <br />
              You are not sure how to launch your social media platforms
              effectively and successfully.
            </p>
            <p>
              <strong>Price?</strong>
              <br />
              Starting from £1.5k
            </p>
          </Details>
          <Details summaryText={`Social Media Management 🛠`}>
            <p>
              This package is designed to help businesses maintain a strong and
              active presence on social media without the hassle of doing it
              themselves. Our team will handle tasks such as scheduling and
              publishing posts, engaging with followers, and monitoring and
              analyzing performance, allowing you to focus on other aspects of
              your business. Don't struggle to keep up with social media
              management - let us handle it for you and watch your online
              presence thrive.
            </p>
            <p>
              <strong>Who is it for?</strong>
              <br />
              You lack the time, skills & knowledge needed to grow online
            </p>
            <p>
              <strong>Price?</strong>
              <br />
              Starting at £10k p/m. custom quotes upon enquiry
            </p>
          </Details>
          <Details summaryText={`Content Creation 🎥`}>
            <p>
              Boost your social media presence and engage with your audience
              with our custom content creation package. Our team of experts will
              craft social media posts, graphics, and videos that showcase your
              unique brand and voice. Let us help you generate fresh ideas and
              execute your vision - we've got you covered.
            </p>
            <p>
              <strong>Who is it for?</strong>
              <br />
              You don't have the time and resources to plan and create content.
            </p>
            <p>
              <strong>Price?</strong>
              <br />
              From £5.5k + p/m - minimum 3 month contract
            </p>
          </Details>
          <Details summaryText={`Strategy & Consultation 💻`}>
            <p>
              Our in-depth social media research and strategy package includes a
              deep dive into your business's current social media presence, as
              well as research on the market and competitors. We will also work
              with you to develop a brand messaging and positioning strategy, as
              well as a plan for your brand identity. In addition, we'll help
              you plan and execute social media campaigns and determine the best
              channels for your business.
            </p>
            <p>
              <strong>Who is it for?</strong>
              <br />
              Has the team but not the strategy to perform well.
            </p>
            <p>
              <strong>Price?</strong>
              <br />
              Starting at £10k p/m. custom quotes upon enquiry
            </p>
          </Details>
        </div>
      </Section>
    </div>
  );
}
