import { useState, useRef } from "react";
import styles from "./OurSection.module.scss";
import Section from "../Section";

export default function OurSection() {
  const [purpose, setPurpose] = useState(true);

  const section3 = useRef();

  return (
    <div ref={section3}>
      <Section
        sectionClass={`bg-dark overflow-clip`}
        containerClass={
          `full-width min-full-height flex-centered flex-column ` +
          styles.ourSection +
          (purpose ? " purpose" : " vision")
        }
        headline={null}
      >
        <>
          <div>
            {purpose ? (
              <>
                <h2>
                  our <span className={`lower underline`}>purpose</span>
                </h2>
                <p>
                  We are driven by a single purpose: to help our clients succeed
                  through innovative, cutting edge strategies. We stay ahead of
                  the curve, using the latest technology and insights to take
                  brand to new heights.
                </p>
              </>
            ) : (
              <>
                <h2>
                  our <span className={`lower underline`}>vision</span>
                </h2>
                <p>
                  Our vision is to position ourselves as the premier social
                  media branding agency, empowering our clients to succeed and
                  supporting them in championing their story.
                </p>
              </>
            )}
            <div
              className={`${styles.prev} ${styles.arrow}`}
              onClick={() => setPurpose(!purpose)}
              tabIndex={0}
            />
            <div
              className={`${styles.next} ${styles.arrow}`}
              onClick={() => setPurpose(!purpose)}
              tabIndex={0}
            />
          </div>
          <div
            className={`bg-secondary ${styles.circle} ${styles.topLeft} ${
              purpose ? "" : styles.vision
            }`}
          />
          <div
            className={`bg-secondary ${styles.circle} ${styles.bottom} ${
              purpose ? "" : styles.vision
            }`}
          />
          <div
            className={`bg-secondary ${styles.circle} ${styles.right} ${
              purpose ? "" : styles.vision
            }`}
          />
          <div
            className={`${styles.rarityR} ${styles.topRight} ${
              purpose ? "" : styles.vision
            }`}
          />
          <div
            className={`${styles.rarityR} ${styles.bottomLeft} ${
              purpose ? "" : styles.vision
            }`}
          />
        </>
      </Section>
    </div>
  );
}
